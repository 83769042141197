<template>
  <div class="search-container max-xl:px-5">
    <div class="w-full max-w-rifyo mx-auto">
      <div class="mt-28 lg:mt-40 md:mb-8">
        <div class="flex flex-col align-center">
          <h1 class="search-text">Search Result</h1>
          <div class="search-text1 text-center" v-if="products.length">
            {{
              `We found ${productTotal} product for “${$route.query.search}”`
            }}
          </div>
          <template v-else>
            <span class="search-text1 text-center">{{
              `There is no result for “${$route.query.search}”. Please choose another keyword or explore our products.`
            }}</span>
            <button
              class="px-8 py-2 empty-cart-btn"
              @click="$router.push('/shop/all')"
              style="background-color: #1f252c; color: white"
            >
              Browse Products
            </button>
          </template>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="loading flex justify-center items-center w-full"
      >
        <Loading />
      </div>
      <div
        v-else
        class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-16"
      >
        <div
          v-for="(product, index) in products"
          :key="'product-' + index"
          class="flex flex-grow-0"
        >
          <shop-card
            :image_src="showImage(product)"
            :title="product.title"
            :hargaDisc="calculatePrice(product)"
            :hargaNormal="normalPrice(product)"
            :item="product"
          />
        </div>
      </div>
      <div class="search-container-pagination" v-if="!isLoading">
        <app-pagination
          :get-page="fecthProduct.bind(this)"
          rootClassName="rootClassName"
          :selected="productParams.page"
          :total="productTotal"
          :per_page="productParams.perPage"
          :current_page="productParams.currPage"
          v-if="productTotal > 0"
        ></app-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import InputFilter from '../components/input-filter';
  import ShopCard from '../components/shop-card';
  import AppPagination from '../components/pagination';
  import Footer from '../components/footer';
  import Loading from '../components/Loading.vue';
  import Api from '../api';

  export default {
    name: 'Search',
    props: {},
    components: {
      InputFilter,
      ShopCard,
      AppPagination,
      Footer,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        products: [],
        productTotal: 0,
        productParams: {
          page: 1,
          perPage: 12,
          currPage: 1,
          orderBy: 'latest',
          categoryId: null,
        },
      };
    },
    computed: {
      slug() {
        return this.$route.params.slug;
      },
      search() {
        return this.$route.query.search;
      },
    },
    created() {
      this.getProduct();
    },
    methods: {
      normalPrice(product) {
        const minPrice = Math.min(
          ...product.product_variants.map((variant) => variant.product.price)
        );
        const startPrice = product.product_variants.find(({ product }) => {
          return product.price == minPrice;
        });
        return startPrice ? startPrice.product.price : product.price;
      },
      calculatePrice(product) {
        const minPrice = Math.min(
          ...product.product_variants.map((variant) => variant.product.price)
        );
        const startPrice = product.product_variants.find(({ product }) => {
          return product.price == minPrice;
        });

        const discountType = startPrice.discount_type;
        const price = parseInt(startPrice.product.price);
        const discount = parseInt(startPrice.maximum_decimal);

        let finalPrice = 0;
        if (discountType === 'percent') {
          finalPrice = price - (price * discount) / 100;
        } else if (discountType === 'fixed') {
          finalPrice = price - discount;
        } else {
          finalPrice = price;
        }
        return finalPrice ? finalPrice : product.price;
      },
      showImage(item) {
        return item?.image?.thumbnail
          ? item.image.thumbnail
          : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
      },
      fecthProduct(page) {
        this.productParams.page = page;
        this.getProduct();
      },
      async getProduct() {
        try {
          this.isLoading = true;
          let fullUrl =
            Api.productUrl +
            `&perpage=${
              this.productParams.perPage ? this.productParams.perPage : '10'
            }&page=${
              this.productParams.page
            }&order=${this.productParams.orderBy.toLowerCase()}`;
          if (this.$route.params.slug != 'all') {
            fullUrl += `&category_slug=${this.$route.params.slug}`;
          }
          if (this.category_id) {
            fullUrl = fullUrl + `&category_id=${this.productParams.categoryId}`;
          }
          if (this.$route.query.search) {
            fullUrl = fullUrl + `&search=${this.$route.query.search}`;
          }
          const response = await this.$http.get(fullUrl);
          this.isLoading = false;
          this.products = response.data.data;
          this.productTotal = response.data.total;
          this.productParams.perPage = response.data.per_page;
        } catch (error) {
          this.isLoading = false;
        }
      },
    },
    watch: {
      slug() {
        this.getProduct();
      },
      search() {
        this.getProduct();
      },
    },
  };
</script>

<style scoped>
  .empty-cart-btn {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-align: left;
    width: max-content;
    margin: 0 auto;
  }
  .loading {
    margin-top: 164px;
    margin-bottom: 164px;
  }
  .search-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .search-header-shop {
    width: 100%;
    display: flex;
    padding-top: 160px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    justify-content: center;
  }
  .search-shop-title {
    width: 240px;
    display: flex;
    flex-direction: column;
  }
  .search-text {
    font-family: Lora;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    color: #1f252c;
  }
  .search-text1 {
    color: #1f252c;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Montserrat;
  }
  .search-filter-row {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
  .search-shop-list {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .search-container-pagination {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
  }
  @media (max-width: 991px) {
    .search-header-shop {
      flex-wrap: wrap;
      padding-left: 16px;
      padding-right: 16px;
    }
    .search-shop-title {
      width: 100%;
    }
    .search-filter-row {
      width: 100%;
      margin-top: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .search-shop-list {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
</style>
